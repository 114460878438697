import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { Container, Row, Col, Button } from "react-bootstrap";

import axios from "axios";
import { BASE_URL } from "../constants/serv";
import { NEW_BASE_URL } from "../constants/serv";

import heroLogo from '../assets/logos/Website logo-01-01.png'
import heroImage from '../assets/images/heroGif-1.gif'

const Hero = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [heroImg, setHeroImg] = useState()

    useEffect(() => {
        const getImg = async () => {
            await axios.get(`${NEW_BASE_URL}/api/hero-image`)
                .then(res => {
                    setHeroImg(res.data)
                })
                .catch(err => console.log('err hero get', err))

        }
        getImg()
    }, [])

    const navigate = useNavigate()
    return (
        <Slider {...settings} className="hero shadow-light-out">
            <Container className="hero-slide-main ">
                <Row>
                    <Col className="col-12 col-lg-6 text-start left-col">
                        <Row>
                            <img className="hero-logo" alt="CANALWALK" src={heroLogo} />
                        </Row>
                        <Row>
                            <h6 className="hero-header">FOR EVERY WALK OF YOUR LIFE...</h6>
                        </Row>
                        <div>
                            <Button id="btn-pri-top-left" onClick={() => navigate(`/products/:all`, { state: { filterValue: 'all' } })}>
                                SHOP NOW
                            </Button>
                        </div>
                    </Col>
                    { // ------------ used for testing - currently hidden
                        <Col className="col-12 col-lg-6 right-col hidden">
                            <div className="center img-cont " key={heroImage}>
                                <img className='hero-image' alt='sneaker' src={heroImage} />
                            </div>
                        </Col>
                    }
                    <Col className="col-12 col-lg-6 right-col center">
                        {
                            <div className="center img-cont" >
                                <img className='hero-image' alt='sneaker' src={heroImg} />
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
            {
                /*

                <Container className="hero-slide-sec hidden">
                    <div className="text-container"></div>
                </Container>

                */
            }
        </Slider>
    )
}


export default Hero;