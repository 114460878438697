import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CartContext } from "../context/CartContext";
import { Container, Row, Col, Button, Stack, Badge } from "react-bootstrap";

import SizeSelectModal from "./SizeSelectModal";

import { BASE_URL } from "../constants/serv";

import placeholdeImg from '../assets/images/img-placeholder.jpg'
import { addToCartIcon } from '../constants/grabIcons'


const ProductCard = (props) => {

    const navigateToProductPage = useNavigate();

    const { _id, sku, brand, name, price, discount, availability, productImages, productImage, gender } = props.data;

    // context for cart
    const { itemsInCart } = useContext(CartContext)
    const [cartItemCount, setCartItemCount] = useState(0)

    useEffect(() => {
        const found = itemsInCart.find(i => i.id === _id && i.count >= 1)
        if (found) {
            setCartItemCount(found.count)
        }
    }, [itemsInCart])


    let percentage = Math.ceil((discount / price) * 100)

    // processing db
    //const productImage = productImages.find(img => img.imgId === 1)
    let priceAfterDiscount = price - discount
    let isAvail

    // checking if product is in stock
    const checkAvailability = () => {
        if (availability=='true') {
            isAvail = 'In Stock'
        }else{
            isAvail = 'Out of Stock'
        }
        return isAvail;
    }

    // select size modal
    const [modalShow, setModalShow] = useState(false);


    return (
        <div className="p-card-margin">
            <Container className={availability ? "shadow-light-out product-card-container" : "product-card-container-out shadow-light-out"}>
                {discount == 0 ? <p></p> : <Badge pill bg='' id="discount-badge">sale %{percentage}</Badge>}
                <div className="card-pad" onClick={() => navigateToProductPage(`/:${_id}`, { state: { productId: _id } })}>
                    <Row>
                        {productImage
                            ? <img alt="product" className="p-image-card" src={productImage} />
                            : <img alt="product" className="p-image-card" src={placeholdeImg} />
                        }
                    </Row>
                    <Row>
                        <Col className="ellip-wrap"><small className="p-brand">{brand}</small></Col>
                        <Col className="text-end">
                            {gender === 'men' || gender === 'women' ?
                                <span className={gender === "men" ? "g-badge txt-m" : gender === "women" ? "g-badge txt-w" : "g-badge txt-u"}>
                                    <small><strong>{gender}</strong></small>
                                </span>

                                : <span className="text-end">
                                    <small className="g-badge txt-u"><strong>{gender}</strong></small>
                                </span>
                            }
                        </Col>
                    </Row>
                    <Row><p className="p-name">{name}</p></Row>
                    <Row>
                        <Stack direction="horizontal" gap={2}>
                            <small className={discount == 0 ? 'no-sale' : 'on-sale'}>EGP <strong>{priceAfterDiscount}</strong></small>
                            <small>
                                {price > priceAfterDiscount
                                    ? <p className="discounted-from">{price}</p>
                                    : <p></p>
                                }
                            </small>
                        </Stack>
                        <Row>
                            <div className="stock-badge-container">
                                <span className={availability=='true' ? 'badge-true' : 'badge-false'}>
                                    {checkAvailability()}
                                </span>
                            </div>
                        </Row>
                    </Row>
                </div>
                <Row>
                    {availability=='true' ?
                        <Col className="col-center">
                            {
                                cartItemCount === 0
                                    ? <Button className='shadow-light-out' id="btn-cart"
                                        onClick={() => setModalShow(true)}>
                                        {addToCartIcon}
                                    </Button>
                                    : <>
                                        <Button className='shadow-light-out' id="btn-cart-item-added">
                                            {cartItemCount}
                                        </Button>
                                    </>
                            }
                            <SizeSelectModal props={props.data} show={modalShow} onHide={() => setModalShow(false)} />
                        </Col>
                        :
                        <Col className="col-center">
                            <Button id="btn-cart-out" disabled>{addToCartIcon}</Button>
                        </Col>
                    }
                </Row>
            </Container>
        </div>
    )
}

export default ProductCard;