import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Row, Col, Button, ButtonGroup, Modal } from "react-bootstrap"
import { NEW_BASE_URL } from "../constants/serv"
import ProductData from "./ProductData"

import { deleteIcon, checkIcon, xIcon } from "../constants/grabIcons"

const ProductsManagement = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [triggerToggle, setTriggerToggle] = useState(true)

    const [products, setProducts] = useState()
    useEffect(() => {
        const fetchProducts = async () => {
            const res = await fetch(`${NEW_BASE_URL}/api/products`)
            //console.log(res)
            var json = await res.json()
            json=json.data
            //console.log(json)

            if (res.ok) {
                setProducts(json)
            } else {
                console.log('error occurred', res)
            }
        }

        fetchProducts()
    }, [triggerToggle])


    // VIEW product
    const [viewModal, setViewModal] = useState(false)
    const [productToView, setProductToView] = useState()

    const openViewModal = (product) => {
        setProductToView(product)
        setViewModal(true)
    }

    // DELETE product
    const [delModalShow, setDelModalShow] = useState(false) //delete product modal
    const [productToDel, setProductToDel] = useState(null)

    const openModalDel = (product) => {
        setProductToDel(product)
        setDelModalShow(true)
    }

    const handleDelete = (product) => {
        setLoading(true)
        const id = product._id
        const deleteProduct = async () => {
            const response = await fetch(`${NEW_BASE_URL}/api/products/${id}`, {
                method: 'DELETE'
            })
            await response.json()

            if (response.ok) {
                setDelModalShow(false)
                setLoading(false)
                setTriggerToggle(!triggerToggle)
            }
        }

        deleteProduct()
    }

    return (
        <div>
            <div className='plain-wrapper'>
                <br />
                <Row>
                    <Col className='col-8'>
                        <h4><strong>Products Managment</strong></h4>
                    </Col>
                    <Col className='col-4'>
                        <div className='text-end'>
                            <Button variant='success' size='sm'
                                onClick={() => navigate('./add-new-product')}>
                                Add New Product +
                            </Button>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    {products &&
                        <small className='grey'>Products currently in your database: <strong>{products.length}</strong></small>
                    }
                </Row>
                <br />
                <Row>
                    {products && products.map((p, i) => (
                        <div className='product-wrapper' key={p._id}>
                            <Row>
                                <Col className='col-12 col-lg-5'>
                                    <Row>
                                        <Col className="col-1 left-mark">
                                            <small><strong>{i + 1}</strong></small>
                                        </Col>
                                        <Col className="col-11">
                                            <small className='grey tiny-txt'>{p.sku}</small>
                                            <br />
                                            <small className="pri-txt"><strong>{p.name}</strong></small>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className='col-6 col-lg-2'>
                                    <small>Price: EGP <strong>{p.price}</strong></small>
                                    <br />
                                    <small>After Discount: <strong>{p.price - p.discount}</strong></small>
                                </Col>
                                <Col className='col-6 col-lg-2'>
                                    <small>Gender: <strong>{p.gender}</strong></small>
                                    <br />
                                    <small>Featured: <strong>{p.featured =='true' ? <>Yes</> : <>No</>}</strong></small>
                                </Col>
                                <Col className='col-12 col-lg-3 py-2'>
                                    <Row>
                                        <Col className='col-4'>
                                            <small className="grey tiny-txt">Stock? </small>
                                            {p.availability =='true'
                                                ? <small className="green">{checkIcon}</small>
                                                : <small className="red">{xIcon}</small>
                                            }
                                        </Col>
                                        <Col className='col-8 text-end border-left '>
                                            <ButtonGroup>
                                                <Button variant='outline-secondary' size='sm'
                                                    onClick={() => openViewModal(p)}>
                                                    View Info
                                                </Button>
                                                <Button variant='outline-danger' size='sm'
                                                    disabled={loading}
                                                    onClick={() => openModalDel(p)}>
                                                    {deleteIcon}
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Modal show={delModalShow} onHide={() => setDelModalShow(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Product</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>Are you sure you want to delete this product?</p>
                                            <p>Product about to be deleted:
                                                <br />
                                                {productToDel
                                                    ? <strong className="red">{productToDel.name} - {productToDel.gender}</strong> //- <strong>{productToDel.gender}</strong>
                                                    : <></>}
                                            </p>
                                            <br />
                                            <small className="grey">Note: deleting this product will not affect previous orders info, but will result in deleting all info (including all images, videos,.. etc) related to this product.</small>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" size="sm" onClick={() => setDelModalShow(false)}>
                                                Close
                                            </Button>
                                            <Button variant="danger" size="sm" onClick={() => handleDelete(productToDel)}>
                                                Delete Product
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {productToView &&
                                    <Modal show={viewModal} onHide={() => setViewModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{productToView.name}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            { /*
                                                <div className="plain-wrapper pri-txt">
                                                <Row>
                                                    <Col className="text-end">
                                                        <Button variant="light" size="sm">Edit</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <small className="grey tiny-txt">{productToView && productToView.sku}</small>
                                                    </Col>
                                                </Row>
                                                {productToView &&
                                                <Row>
                                                    <Col className="col-12 col-md-2 grey"><strong>{productToView.brand}</strong></Col>
                                                    <Col className="col-12 col-md-10"><strong>{productToView.name}</strong></Col>
                                                </Row>}
                                                <br />
                                                { productToView &&
                                                <Row>
                                                    <Col>
                                                        <Stack>
                                                            <small><span className="grey">Price: </span><strong>{productToView.price}</strong></small>
                                                            <small><span className="grey">Discount: </span><strong>{productToView.discount}</strong></small>
                                                            <small><span className="grey">Gender: </span><strong>{productToView.gender}</strong></small>
                                                            <small><span className="grey">Category: </span><strong>{productToView.category}</strong></small>
                                                            <small><span className="grey">Color: </span><strong>{productToView.color}</strong></small>
                                                            <small><span className="grey">Material: </span><strong>{productToView.material}</strong></small>
                                                            <small><span className="grey">Availablility: </span>{productToView.availability ? <strong>in stock</strong> : <strong>out of stock</strong>}</small>
                                                            <small><span className="grey">Featured: </span>{productToView.featured ? <strong>Yes</strong> : <strong>No</strong>}</small>

                                                        </Stack>
                                                    </Col>
                                                    <Col className="border-left">
                                                        <Stack direction="horizontal" gap={3} className="grey">
                                                            <small>Size</small>
                                                            <small>Stock</small>
                                                        </Stack>
                                                        {
                                                            productToView.sizeSheet.map((row, i) => (
                                                                <div key={`${row.size}+${i}`}>
                                                                    <Stack direction="horizontal" gap={4}>
                                                                        <small><strong>{row.size}</strong></small>
                                                                        <small><strong>{row.qty}</strong></small>
                                                                    </Stack>
                                                                </div>
                                                            ))
                                                        }
                                                    </Col>
                                                </Row>
                                                }
                                                </div>
                                            */ }
                                            <ProductData props={productToView}/>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" size="sm" onClick={() => setViewModal(false)}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    }
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Row>

            </div>
            <br />
            <Row className="hidden">
                <div className='center hidden'>
                    <Button variant='outline-secondary' >Load More</Button>
                </div>
            </Row>
        </div>
    )
}

export default ProductsManagement