import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Col, Row, Stack, Button, Overlay } from "react-bootstrap";

import SizeChart from "./SizeChart";
import { CartContext } from "../context/CartContext";

const ProductInfo = ({ props }) => {
    let { _id, brand, name, discount, price, availability, sizeSheet, gender, category,is_one_size } = props;

    sizeSheet = JSON.parse(sizeSheet);

    //context for cart
    const { dispatch } = useContext(CartContext)
    const [cartItemCount, setCartItemCount] = useState(0)

    const navigate = useNavigate()

    // please select a size tooltip (error tooltip)
    const [tooltipShow, setTooltipShow] = useState(false);
    const tooltipTarget = useRef(null)

    // successfully added to cart tooltip (error tooltip)
    const [successTooltipShow, setSuccessTooltipShow] = useState(false);
    const successTooltipTarget = useRef(null)

    const handleCart = ({ type, id, sizeChosen, count }) => {
        setSuccessTooltipShow(!successTooltipShow)
        dispatch({ type, id, sizeChosen, count, name, gender, discount, price })
        setCartItemCount(count)
    }

    let availabilityText

    let sizeIsAvail = false

    const [sizeChosen, setSizeChosen] = useState(0);
    const [qtyChosen, setQtyChosen] = useState(1)

    // sizechart modal
    const [modalShow, setModalShow] = useState(false);


    // checking if product is in stock
    const checkAvailability = () => {
        if (availability == 'true') {
            availabilityText = 'In Stock'
        }else {
            availabilityText = 'Out of Stock'
        }
        return availabilityText;
    }

    // checking size availability to render in JSX elements
    const checkIfAvail = (value) => {
        for (let i = 0; i < sizeSheet.length; i++) {
            if (sizeSheet[i].size === value) {
                if (sizeSheet[i].qty > 0) {
                    sizeIsAvail = true;
                    return sizeIsAvail;
                } else {
                    sizeIsAvail = false;
                    return sizeIsAvail;
                }
            }
        }
    }

    let maxQtyPerSize = []
    let ArrPerSize = []
    sizeSheet.map((item, i) => {
        if (item.qty !== 0) {
            maxQtyPerSize[i] = item.qty
        }
        return maxQtyPerSize
    })

    const grabQty = (sizeChosen) => {
        //console.log(sizeChosen)
        if (sizeChosen !== 0) {
            const index = sizeSheet.findIndex(item => sizeChosen == Number(item.size))
            //console.log(index)
            for (let i = 0; i < sizeSheet[index].qty; i++) {
                ArrPerSize[i] = i + 1
            }
            //console.log(ArrPerSize)
        }
        return ArrPerSize
    }


    // reading size chosen and checking available quantity of that size
    const handleSizeChosen = (value) => {
        setSizeChosen(value);
    }


    //console.log(qty, maxQty, maxQtyPerSize)

    return (
        <div className="section-y-margin">
            <Container className="product-info-container">
                <Row>
                    {brand ?
                        <Col className="col-6 text-start">
                            <small>Brand:</small>
                            <button className="link-btn"
                                onClick={() => navigate(`/products/:${brand}`, { state: { filterValue: brand } })}>
                                <small><strong>{brand}</strong></small>
                            </button>
                        </Col>
                        : <></>
                    }
                    <Col className="text-end">
                        {gender === 'men' || gender === 'women' ?
                            <span className={gender === "men" ? "g-badge txt-m" : gender === "women" ? "g-badge txt-w" : "g-badge txt-u"}>
                                <small><strong>{gender}</strong></small>
                            </span>

                            : <span className="text-end">
                                <small className="g-badge txt-u"><strong>{gender}</strong></small>
                            </span>
                        }
                    </Col>
                </Row>
                <Row>
                    <p className="product-name">{name}</p>
                </Row>
                <Row>
                    <Stack direction="horizontal" gap={2}>
                        <p className="product-price">EGP</p>
                        {discount != 0 ?
                            <Stack direction="horizontal" gap={2}>
                                <p className="product-price">{price - discount}</p>
                                <p className='product-price-off'>{price}</p>
                            </Stack>
                            :
                            <Stack direction="horizontal" gap={2}>
                                <p className="product-price">{price - discount}</p>
                            </Stack>
                        }
                    </Stack>
                </Row>
                <Row>
                    <div className="stock-badge-container">
                        <span className={availability=='true' ? 'badge-true' : 'badge-false'}>
                            {checkAvailability()}
                        </span>
                    </div>
                </Row>
                {
                    is_one_size === 0
                        ? <>
                            <Row>
                                <Stack direction='horizontal' gap={3}>
                                    <strong>SIZE</strong>
                                    <button className="link-btn"
                                        onClick={() => setModalShow(true)}>
                                        Size Chart
                                    </button>
                                    <SizeChart
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                </Stack>
                            </Row>
                            <Row>
                                {gender === 'women'
                                    ?
                                    <Stack className='size-select' direction='horizontal' gap={1}>
                                        <div>
                                            <input
                                                type="radio" name="s-size"
                                                className='input' id="thirty-six"
                                                value={36} disabled={!checkIfAvail(36)}
                                                onChange={e => handleSizeChosen(e.target.value)}
                                            />
                                            <label htmlFor="thirty-six" className={sizeIsAvail ? 'label' : 'disabled'}>36</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio" name="s-size"
                                                className='input' id="thirty-seven"
                                                value={37} disabled={!checkIfAvail(37)}
                                                onChange={e => { handleSizeChosen(e.target.value) }}
                                            />
                                            <label htmlFor="thirty-seven" className={sizeIsAvail ? 'label' : 'disabled'}>37</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio" name="s-size"
                                                className='input' id="thirty-eight"
                                                value={38} disabled={!checkIfAvail(38)}
                                                onChange={e => { handleSizeChosen(e.target.value) }}
                                            />
                                            <label htmlFor="thirty-eight" className={sizeIsAvail ? 'label' : 'disabled'}>38</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio" name="s-size"
                                                className='input' id="thirty-nine"
                                                value={39} disabled={!checkIfAvail(39)}
                                                onChange={e => { handleSizeChosen(e.target.value) }}
                                            />
                                            <label htmlFor="thirty-nine" className={sizeIsAvail ? 'label' : 'disabled'}>39</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio" name="s-size"
                                                className='input' id="forty"
                                                value={40} disabled={!checkIfAvail(40)}
                                                onChange={e => { handleSizeChosen(e.target.value) }}
                                            />
                                            <label htmlFor="forty" className={sizeIsAvail ? 'label' : 'disabled'}>40</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio" name="s-size"
                                                className='input' id="forty-one"
                                                value={41} disabled={!checkIfAvail(41)}
                                                onChange={e => { handleSizeChosen(e.target.value) }}
                                            />
                                            <label htmlFor="forty-one" className={sizeIsAvail ? 'label' : 'disabled'}>41</label>
                                        </div>
                                    </Stack>
                                    : gender === 'men' ?
                                        <Stack className='size-select' direction='horizontal' gap={1}>
                                            <div>
                                                <input
                                                    type="radio" name="s-size"
                                                    className='input' id="forty-one"
                                                    value={41} disabled={!checkIfAvail(41)}
                                                    onChange={e => handleSizeChosen(e.target.value)}
                                                />
                                                <label htmlFor="forty-one" className={sizeIsAvail ? 'label' : 'disabled'}>41</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio" name="s-size"
                                                    className='input' id="forty-two"
                                                    value={42} disabled={!checkIfAvail(42)}
                                                    onChange={e => { handleSizeChosen(e.target.value) }}
                                                />
                                                <label htmlFor="forty-two" className={sizeIsAvail ? 'label' : 'disabled'}>42</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio" name="s-size"
                                                    className='input' id="forty-three"
                                                    value={43} disabled={!checkIfAvail(43)}
                                                    onChange={e => { handleSizeChosen(e.target.value) }}
                                                />
                                                <label htmlFor="forty-three" className={sizeIsAvail ? 'label' : 'disabled'}>43</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio" name="s-size"
                                                    className='input' id="forty-four"
                                                    value={44} disabled={!checkIfAvail(44)}
                                                    onChange={e => { handleSizeChosen(e.target.value) }}
                                                />
                                                <label htmlFor="forty-four" className={sizeIsAvail ? 'label' : 'disabled'}>44</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio" name="s-size"
                                                    className='input' id="forty-five"
                                                    value={45} disabled={!checkIfAvail(45)}
                                                    onChange={e => { handleSizeChosen(e.target.value) }}
                                                />
                                                <label htmlFor="forty-five" className={sizeIsAvail ? 'label' : 'disabled'}>45</label>
                                            </div>
                                        </Stack>
                                        : <></>
                                }
                            </Row>
                        </>
                        : is_one_size === 1
                            ? <>
                                <Row>
                                    <strong>SIZE</strong>
                                    <Stack className='size-select' direction='horizontal' gap={1}>
                                        <div>
                                            <input
                                                type="radio" name="one-size"
                                                className='input' id="one-size"
                                                value={1} 
                                                onChange={e => { handleSizeChosen(e.target.value) }}
                                            />
                                            <label htmlFor="one-size" className='onesize-label'>one size</label>
                                        </div>
                                    </Stack>
                                </Row>
                            </>
                            :
                            <></>
                }
                <Row>
                    <strong>QUANTITY</strong>
                </Row>
                <Row>
                    <div>
                        <select name="field" className="qnty-select" value={qtyChosen}
                            onChange={e => {
                                setQtyChosen(e.target.value)
                                //console.log('qtyChosen e.target', e.target.value)
                            }}>
                            <option>Select quantity</option>
                            {
                                grabQty(sizeChosen).map((value, index) => (
                                    <option key={index}>{value}</option>
                                ))}
                        </select>
                    </div>
                </Row>
                <Row>
                    <div className="divider-narrow">
                        <hr className="divider-line" />
                    </div>
                </Row>
                {availability =='true'
                    ?
                    <>
                        {sizeChosen ?
                            <Row>
                                {cartItemCount === 0
                                    ? <Row ref={successTooltipTarget}>
                                        <Button size="lg" id="btn-pri-bottom-left"
                                            onClick={() => {
                                                handleCart({ type: 'ADD_TO_CART', id: _id, sizeChosen: sizeChosen, count: qtyChosen })
                                                setCartItemCount(qtyChosen)
                                            }}>
                                            ADD TO CART
                                        </Button>
                                        <Button size="lg" id="btn-sec-top-left"
                                            onClick={() => {
                                                handleCart({ type: 'ADD_TO_CART', id: _id, sizeChosen, count: qtyChosen })
                                                setCartItemCount(qtyChosen)
                                                navigate(`/cart`)
                                            }}>
                                            BUY NOW
                                        </Button>
                                        {/*
                        <Overlay target={successTooltipTarget.current} show={successTooltipShow} placement="top">
                            {({ placement: _placement, arrowProps: _arrowProps, show: _show,
                                popper: _popper, hasDoneInitialMeasure: _hasDoneInitialMeasure, ...tooltipProps
                            }) => (
                            <div {...tooltipProps} className="success-tootip" style={{ ...tooltipProps.style }}>
                                Product has been successfully added to cart!
                            </div>
                            )}
                        </Overlay>
                        */}
                                    </Row>
                                    : <Row className="edit-group-btns">
                                        {/*
                        <Col className="col-2">
                            <Button id="btn-minus"
                                onClick={()=> {handleCart({type: 'REMOVE_FROM_CART', id}, inCartItems[myIndex].count-1)}}>
                                {minusIcon}
                            </Button>
                        </Col>
                        */}
                                        <Col className="col-12">
                                            <Button className='' id="btn-cart-item-added" >
                                                Added to cart ( {qtyChosen} )
                                            </Button>
                                        </Col>
                                        {/*
                        <Col className="col-2">
                            <Button id="btn-plus"
                                onClick={()=> {handleCart({type: 'ADD_TO_CART', id}, inCartItems[myIndex].count+1)}}>
                                {plusIcon}
                            </Button>
                        </Col>
                        */}
                                    </Row>
                                }
                                {/*
                        <CartModal
                            show={cartModalShow} info={userOrderDetails} item={productInfo}
                            onHide={() => setCartModalShow(false)}
                        />
                    */}
                            </Row>
                            :
                            <Row ref={tooltipTarget}>
                                <Button id="btn-pri-bottom-left"
                                    onClick={() => setTooltipShow(!tooltipShow)}>
                                    ADD TO CART
                                </Button>
                                <Button id="btn-sec-top-left"
                                    onClick={() => setTooltipShow(!tooltipShow)}>
                                    BUY NOW
                                </Button>
                                <Overlay target={tooltipTarget.current} show={tooltipShow} placement="top">
                                    {({ placement: _placement, arrowProps: _arrowProps, show: _show,
                                        popper: _popper, hasDoneInitialMeasure: _hasDoneInitialMeasure, ...props
                                    }) => (
                                        <div {...props} className="error-tootip" style={{ ...props.style }}>
                                            Please select size
                                        </div>
                                    )}
                                </Overlay>
                            </Row>
                        }
                    </>
                    : <Row>
                        <Button size="lg" id="btn-pri-bottom-left" ref={successTooltipTarget} disabled>
                            OUT OF STOCK
                        </Button>
                        <Button size="lg" id="btn-sec-top-left" ref={successTooltipTarget} disabled>
                            REQUEST PRODUCT
                        </Button>
                    </Row>
                }
                {/*
                <Row>
                    <div className="position-absolute top-70 start-50 translate-middle">
                        <Alert variant="success" show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                            <small className="successful center">Product has been successfully added to cart {}</small>
                        </Alert>
                    </div>
                </Row>
                */}
            </Container>
        </div>
    )
}

// {()=> {handleCart({type: 'ADD_TO_CART', id}, inCartItems[index].count+1)}}

export default ProductInfo;